<template>
    <div>
    
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "History",
        data(){
            return {
                user: {}
            }
        },
        mounted() {
            axios.get(`${process.env.VUE_APP_URL_API}/user`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.user = res.data
            }).catch((err) => console.error(err))
        },
    }
</script>
